%range-track {
  height: 10px;
  background: linear-gradient(to right, #004ECA, #14CB89);
  border-radius: 5px;
}

.pricing-calculator {
  &__software-button {
    width: 3.75rem;
    height: 3.75rem;
    border: 2px solid #DDD;
    border-radius: var(--bs-border-radius-lg);
    padding: 0.75rem;
    transition: all 0.15s ease-in-out;

    &:hover,
    &:active {
      background-color: #ffffff !important;
      border-color: rgba(var(--bs-primary-rgb)) !important;
    }

    &--selected {
      border-color: rgba(var(--bs-primary-rgb)) !important;
      border-width: 2px;
    }

    &--disabled {
      background-color: #DDD;
      cursor: not-allowed;

      img {
        filter: grayscale(1);
        opacity: 0.75;
      }

      &:hover {
        background-color: #DDD !important;
        border: 2px solid #DDD !important;
      }
    }
  }

  &__form {
    background-image: linear-gradient(to bottom, #1F77EC, #0046A7);
  }

  &__gradient-range {
    -webkit-appearance: none;
    width: 100%;
    outline: none;
    @extend %range-track;
  }

  &__gradient-range::-webkit-slider-runnable-track {
    @extend %range-track;
  }

  &__gradient-range::-moz-range-track {
    @extend %range-track;
  }
}