.about-page {
  padding: 8rem 2rem;
  & .about-socials {
    margin-top: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    &__item {
      width: 220px;
      //padding: 1.5rem;
    }
    &__link {
      height: 3.75rem;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      column-gap: 1rem;
      padding: 1rem;
      font-size: 1.125rem;
      font-weight: 700;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius);
      cursor: pointer;
      & img {
        width: 2.5rem;
        height: auto;
      }
      & span {
        font-size: inherit;
        font-weight: inherit;
      }
      &:hover {
        border-color: var(--border-hover-color);
      }
    }
  }
}
