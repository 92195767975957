.home-page {
  padding: 8rem 2rem;
  & .promo-wrapper {
    margin-top: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  & .promo-card {
    max-width: 1248px;
    margin: 0 auto;
    padding: 1rem;
    border: 0 solid var(--border-color);
    border-radius: var(--border-large-radius);
    background-color: rgba(17, 24, 39, 0.05);
    box-shadow: rgb(255, 255, 255) 0 0 0 0 inset, rgba(17, 24, 39, 0.1) 0 0 0 1px inset, rgba(0, 0, 0, 0) 0 0 0 0;
  }
  & .promo-img-wrapper {
    border: 0 solid var(--border-color);
    border-radius: var(--border-radius);
    box-shadow: rgb(255, 255, 255) 0 0 0 0, rgba(17, 24, 39, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.25) 0 25px 50px -12px;
    overflow: hidden;
  }
  & .counters-promo {
    &__box {
      position: relative;
      overflow: hidden;
      margin-top: 3.5rem;
      margin-bottom: 6rem;
      padding: 6rem 4rem 3rem;
      border-radius: var(--border-xl-radius);
      background: var(--text-color);
      background-position: center bottom;
      box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.25) 0 25px 50px -12px;
      text-align: center;
      & .bg-gradient-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        height: 1024px;
        bottom: -640px;
        background: radial-gradient(circle, #323f78 0%, var(--text-color) 50%);
      }
    }
    &__content-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--white-color);
      z-index: 1;
    }
    &__title {
      max-width: 36rem;
      font-size: 2.25rem;
      line-height: 2.5rem;
      //letter-spacing: -0.025em;
      color: inherit;
      @media screen and (max-width: 640px) {
        font-size: 1.875rem;
      }
    }
    &__description {
      max-width: 36rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: var(--border-hover-color);
      margin-top: 1.5rem;
      margin-bottom: 2.5rem;
    }
    & .btn-link, & .btn-link i {
      color: var(--white-color);
      &:hover {
        color: var(--white-hover-color);
      }
    }
    &__counters {
      width: 100%;
      color: inherit;
      margin-top: 7rem;
    }
    &__counters-item {
      display: flex;
      flex-direction: column;
      border-left: 1px solid hsla(0, 0%, 100%, 0.2);
      color: inherit;
      row-gap: 0.75rem;
      margin-bottom: 3rem;
      &:first-child {
        border-left-color: transparent;
      }
      &:nth-child(odd) {
        @media screen and (max-width: 991px) {
          border-left-color: transparent;
        }
      }
      @media screen and (max-width: 767px) {
        border-left-color: transparent;
      }
    }
    &__counter-value {
      font-size: 1.875rem;
      line-height: 2.25rem;
      //letter-spacing: -0.025em;
      font-weight: 800;
      color: inherit;
    }
    &__counter-name {
      font-size: 1rem;
      line-height: 1.75rem;
      color: inherit;
    }
  }
  & .additional-content {
    display: grid;
    gap: 2rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    & .fa, & .tr {
      margin-right: 0.25rem;
      font-size: 1.5rem;
      color: var(--main-blue-500);
    }
    &__grid-5 {
      grid-template-columns: repeat(5,minmax(0,1fr));
      margin-top: 2.5rem;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1,minmax(0,1fr));
      }
    }
    &__grid-2 {
      grid-template-columns: repeat(2,minmax(0,1fr));
      margin-top: 2rem;
      margin-bottom: 2.5rem;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1,minmax(0,1fr));
      }
    }
    &__grid-3 {
      grid-template-columns: repeat(3,minmax(0,1fr));
      margin: 0.5rem 0 3.5rem;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1,minmax(0,1fr));
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__image-wrapper {
      overflow: hidden;
      border-radius: var(--border-radius);
    }
    &__text-wrapper {
      padding: 1rem;
    }
    &__title {
      display: flex;
      font-size: 1rem;
      font-weight: 600;
      line-height: 150%;
      margin-top: 0.5rem;
    }
    &__description {
      color: var(--text-alt-color);
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
  &__carousel-wrapper {
    max-width: 1100px;
    margin: 0 auto;
    filter: grayscale(100%);
    & img {
      width: auto;
      height: 32px;
    }
  }
}

.carousel {
  &__slick-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
  }
  &__image {
    //height: 1.5rem;
    width: auto;
    height: 32px;
  }
}

.join-promo {
  &__box {
    //position: relative;
    //overflow: hidden;
    margin: 0;
    padding: 8rem 4rem;
    //background: var(--text-color);
    //background-position: center bottom;
    text-align: center;
    background: linear-gradient(66deg, #0043AB -47.12%, #04152B 78.6%);
    //& .bg-gradient-wrapper {
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  height: 1024px;
    //  bottom: -640px;
    //  background: radial-gradient(circle, #323f78 0%, var(--text-color) 50%);
    //}
  }
  &__content-wrapper {
    //position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--neutral-25);
    //z-index: 1;
  }
  &__title {
    max-width: 48rem;
    font-size: 3rem;
    font-weight: 800;
    line-height: 2.5rem;
    //letter-spacing: -0.025em;
    color: inherit;
    @media screen and (max-width: 640px) {
      font-size: 1.875rem;
    }
  }
  &__description {
    max-width: 48rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: inherit;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }
  & .btn-link, & .btn-link i {
    color: var(--white-color);
    &:hover {
      color: var(--white-hover-color);
    }
  }
}
