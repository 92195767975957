.faq-page {
  padding: 2rem;
  &__title {
    font-size: 2rem;
    line-height: 3rem;
    border-color: transparent;
  }
  &__title-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    background: var(--primary-light-color);
    & img {
      width: 100%;
      border-radius: 6px;
    }
  }
  & .card-text {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
}
