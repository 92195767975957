.default-layout {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  & main.main {
    flex: 1;
    margin-top: calc(2.5rem + 32px);
  }
}

.default-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid var(--neutral-100);
  background: rgba(255, 255, 255, 0.95);
  z-index: 9999;
  & .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1.25rem;
  }
  &__logo-wrapper > a {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  &__logo-title {
    margin-left: 12px;
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 22px;
  }
  &__join-wrapper {
    & .nav-item {
      position: relative;
      cursor: pointer;
      & > a {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: var(--text-color);
        font-weight: 600;
        text-decoration: none;
        margin: -5px;
        padding: 5px;
      }
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 2px;
        border: 1px solid transparent;
        border-radius: 1px;
        left: 50%;
        bottom: 0;
        transition: width 0.15s ease-in-out, left 0.15s ease-in-out;
      }
      &:hover::before {
        width: 100%;
        left: 0;
        border-color: var(--text-color);
      }
    }
  }
}

.default-footer {
  background: var(--footer-bg-color);
  color: var(--neutral-25);
  & .container {
    padding: 5rem 0.875rem 2.5rem;
  }
  & .default-header__logo-wrapper {
    margin-bottom: 1.25rem;
  }
  & .default-header__logo-title {
    color: var(--neutral-25);
  }
  & .footer-copyright {
    font-size: 1.25rem;
    color: var(--neutral-25);
    margin-top: 2rem;
  }
  & .footer-links {
    display: grid;
    max-width: 680px;
    gap: 2rem;
    padding: 0.75rem 0;
    grid-template-columns: repeat(3,minmax(0,1fr));
    color: var(--neutral-25);
    &__title {
      font-family: Montserrat, sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 130%;
      color: var(--neutral-25);
      margin-bottom: 2rem;
    }
    & ul {
      margin: 0;
      padding: 0;
      list-style: none;
      & > li {
        margin: 0 0 0.75rem 0;
        padding: 0;
        & a {
          font-weight: 400;
          color: var(--neutral-25);
          line-height: 150%;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    @media screen and (max-width: 1060px) {
      grid-template-columns: repeat(1,minmax(0,1fr));
    }
  }
}

.default-navigation {
  & .navbar {
    padding: 0;
    column-gap: 3rem;
  }
  & .nav-item {
    position: relative;
    cursor: pointer;
    & > a {
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--black-color);
      font-weight: 600;
      text-decoration: none;
      margin: -5px;
      padding: 5px;
    }
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      border: 1px solid transparent;
      border-radius: 1px;
      left: 50%;
      bottom: 0;
      transition: width 0.15s ease-in-out, left 0.15s ease-in-out;
    }
    &:hover::before {
      width: 100%;
      left: 0;
      border-color: var(--black-color);
    }
    &--current {
      cursor: default;
      & > a {
        color: var(--main-blue-500);
        cursor: default;
      }
      &::before {
        width: 0;
        left: 50%;
        border-color: transparent;
      }
      &:hover::before {
        width: 100%;
        left: 0;
        border-color: var(--main-blue-500);
      }
    }
  }
}
