
// Use on Home AreURoot
.page-title {
  font-family: Montserrat, sans-serif;
  font-size: 3.25rem;
  font-weight: 800;
  line-height: 120%;
  color: var(--neutral-900);
  text-align: center;
  //letter-spacing: -0.025em;
  @media screen and (max-width: 640px) {
    font-size: 2.25rem;
  }
  &--sm {
    font-size: 2.25rem;
    font-weight: 700;
    //font-family: Inter, sans-serif;
    @media screen and (max-width: 640px) {
      font-size: 1.875rem;
    }
  }
}

// Use on Home AreURoot
.page-subtitle {
  max-width: 744px;
  margin: 1rem auto 2.5rem;
  //margin-top: 1rem;
  //margin-bottom: 2.5rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 150%;
  //letter-spacing: -0.5px;
  color: var(--neutral-800);
  text-align: center;
}

.page-label {
  font-family: Montserrat, sans-serif;
  font-size: 2rem;
  font-weight: 800;
  line-height: 150%;
  color: var(--main-blue-500);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0.5rem;
  &--sm {
    font-size: 1.25rem;
  }
}

// Use on Home AreURoot
.content-row {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  &__col {
    padding: 1rem 7.375rem 1rem 1rem;
    @media screen and (max-width: 991px) {
      padding: 1rem;
    }
  }
  &__col-inner {
    //max-width: 32rem;
  }
  &__label {
    font-family: Montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.75rem;
    color: var(--main-blue-500);
    text-transform: uppercase;
  }
  &__title {
    font-family: Montserrat, sans-serif;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 2.75rem;
    //letter-spacing: -0.025em;
    margin-top: 0.5rem;
    color: var(--neutral-900);
    @media screen and (max-width: 640px) {
      font-size: 1.875rem;
    }
  }
  &__description {
    font-size: 1.25rem;
    line-height: 150%;
    font-weight: 400;
    //letter-spacing: -0.025em;
    color: var(--neutral-800);
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &__content-items-wrapper {
    position: relative;
    min-height: 250px;
  }
  &__content-items-indicators {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
  }
  &__content-items-indicator {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: var(--neutral-300);
    transition: background 0.5s ease;
    cursor: pointer;
    &--active {
      background: var(--main-blue-500);
      cursor: default;
    }
  }
  &__content-item {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40px;
    display: flex;
    flex-wrap: nowrap;
    //margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    column-gap: 0.25rem;
    background: var(--main-blue-25);
    opacity: 0;
    transition: opacity 0.5s ease;
    & .fa, & .tr {
      margin-top: -0.25rem;
      font-size: 1.5rem;
      color: var(--main-blue-500);
    }
    & span, & strong {
      font-size: 1rem;
      line-height: 150%;
      color: var(--neutral-800);
    }
    &--active {
      background: var(--main-blue-25);
      opacity: 1;
    }
  }
  &__image-col {
    padding: 1rem;
  }
  &__image-wrapper {
    margin-top: 2.375rem;
    position: relative;
    border-radius: var(--border-large-radius);
  }
  &__image {
    //position: absolute;
    //top: 0;
    //left: 0;
    //opacity: 0;
    width: 100%;
    border-radius: var(--border-large-radius);
    &--fade-in {
      opacity: 1;
    }
    &--fade-out {
      opacity: 0;
    }
  }
  &--rtl {
    flex-direction: row-reverse;
    & .content-row__col {
      padding: 1rem 1rem 1rem 7.375rem;
      @media screen and (max-width: 991px) {
        padding: 1rem;
      }
    }
  }
}
