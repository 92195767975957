:root {
  //   Colors
  //--primary-color: #28b498;
  //--primary-color: #3e76fe;
  //--primary-hover-color: #3364db;
  --primary-color: #14cb89;
  --primary-hover-color: #17e89d;
  --primary-active-color: #12b47a;
  --primary-light-color: #e2fcf3;
  --primary-light-active-color: #c5f9e6;
  --primary-border-color: #109e6b;
  --neutral-25: #fafafa;
  --neutral-color: #f0f2f6;
  --neutral-100: #D9DDEA;
  --neutral-300: #a6acc4;
  --neutral-800: #313545;
  --neutral-900: #1e202c;
  --main-blue-25: #F4F8FE;
  --main-blue-500: #0068FF;

  --white-color: #ffffff;
  --white-hover-color: #f3f4f6;
  --black-color: #000000;
  //--black-color: #212123;
  --text-color: #111827;
  --text-alt-color: #4b5563;
  --border-color: #e5e7eb;
  --border-hover-color: #cfd1d4;
  --footer-bg-color: #001022;

  //   Sizes
  --border-radius: 0.375rem;
  --border-large-radius: 1rem;
  --border-xl-radius: 1.5rem;
}
