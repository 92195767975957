.terms-page {
  max-width: 1170px;
  color: var(--neutral-800);
  line-height: 150%;
  .terms {
    &__page-title {
      margin-bottom: 2rem;
    }
    &__date {
      font-size: 1.25rem;
      text-align: right;
      margin-bottom: 1.5rem;
    }
    &__chapter {
      margin: 1.75rem auto 1rem;
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 150%;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    &__partition {
      margin: 1rem auto;
      font-size: 1.25rem;
      line-height: 150%;
    }
    &__link {
      text-decoration: underline;
      transition: color 0.15s ease;
      &:hover {
        color: var(--main-blue-500);
      }
    }
  }
}
