.composite-link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  column-gap: 1rem;
  &__desc1 {
    font-weight: 800;
    color: var(--primary-color);
  }
  &__desc2 {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: var(--text-alt-color);
    & > svg {
      height: 1.25rem;
      margin-left: 0.25rem;
      & > path {
        color: rgb(156 163 175);
      }
    }
  }
  &__separator {
    width: 1px;
    height: 1rem;
    background: var(--border-color);
  }
  &:hover {
    border-color: var(--border-hover-color);
  }
}

.composite-rounded {
  padding: 0.375rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--text-alt-color);
  border: 1px solid var(--border-color);
  border-radius: 1.125rem;
  &:hover {
    border-color: var(--border-hover-color);
  }
}

.button-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}
