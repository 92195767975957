* {
  color: var(--black-color);
  line-height: 1.5;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: #fdfdfd;
}

a {
  color: var(--black-color);
  font-weight: 800;
  cursor: pointer;
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: var(--text-alt-color);
}

img, video {
  max-width: 100%;
  height: auto;
}

.color-primary {
  color: var(--primary-color);
}

.mirror-x {
  transform: rotateY(180deg);
}

.mirror-y {
  transform: rotateX(180deg);
}

// Redefine Bootstrap defaults
// max-width: lg=991 md=767

.container {
  max-width: 1536px;
}

.btn {
  font-size: 1rem;
  font-weight: 600;
  &.btn-primary {
    color: var(--main-blue-25);
    background: var(--main-blue-500);
    border-color: var(--main-blue-500);
    & > span, & > .fa {
      color: inherit;
    }
    &:hover {
      color: var(--main-blue-25);
      background: var(--main-blue-500);
      border-color: var(--main-blue-500);
    }
    &:active, &:focus {
      color: var(--main-blue-25);
      background: var(--main-blue-500);
      border-color: var(--main-blue-500);
    }
  }
  &-link {
    color: var(--text-color);
    text-decoration: none;
    &:hover {
      color: var(--text-color);
    }
  }
  &.btn-white {
    color: var(--text-color);
    background: var(--white-color);
    &:hover {
      background: var(--white-hover-color);
    }
  }
  &.btn-outline-info {
    color: var(--main-blue-500);
    background: var(--white-color);
    border-color: var(--main-blue-500);
    &:hover {
      color: var(--main-blue-500);
      background: var(--main-blue-25);
      border-color: var(--main-blue-500);
    }
    &:active, &:focus {
      color: var(--main-blue-500);
      background: var(--main-blue-25);
      border-color: var(--main-blue-500);
    }
  }
}


//

.container-middle {
  max-width: 1000px;
  //max-width: 792px;
  margin: 0 auto;
}

.default-page {
  padding: 8rem 2rem;
}
