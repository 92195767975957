.pricing-page {
  padding: 8rem 2rem;
  & .packs {
    &__wrapper {
      display: flex;
      justify-content: center;
      gap: 2rem;
    }
    &__cell {
      align-self: stretch;
      margin-bottom: 1.5rem;
    }
    &__card {
      max-width: 47%;
      height: 100%;
      padding: 2.5rem;
      border: 1px solid var(--border-color);
      border-radius: var(--border-xl-radius);
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
      &-title {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 2rem;
      }
      &-description {
        margin-top: 1rem;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
      &-price {
        margin-top: 1.5rem;
        font-size: 2.225rem;
        font-weight: 700;
        line-height: 2.5rem;
      }
      &-conditions {
        margin: 2.5rem 0 0;
        padding: 0;
        list-style: none;
      }
      &-condition-item {
        margin: 0 0 0.75rem 0;
        font-size: 0.875rem;
        line-height: 1.5rem;
        & > i {
          margin-right: 0.75rem;
          color: var(--primary-color);
          font-size: 1rem;
        }
      }
      & .btn {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
}
